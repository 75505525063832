<template>
	<div>
		<CRow>
			<CCol class="text-right">
				<router-link
					:to="{ name: 'NotificationCreate' }"
					class="btn btn-secondary mb-3"
				>
					<CIcon class="mr-2" name="cil-plus" />
					Create notification
				</router-link>
			</CCol>
		</CRow>

		<CRow>
			<CCol class="d-flex justify-content-between">
				<div class="counter">
					{{ list.meta.total }} notification found
				</div>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: list.meta.lastPage,
						activePage: list.meta.currentPage,
					}"
					:fields="NOTIFICATION_TABLE_FIELDS"
					:items="list.data"
					clickable-rows
					striped
					link-to="NotificationEdit"
					vertical-align="top"
					class="table-custom"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t('global.searchNotFound', { field: 'notification', }) }}
							</p>
						</div>
					</template>
					<template #status="{item}">
						<CBadge class="badge-status" :color="NOTIFICATION_COLOR.STATUS[item]">
							{{ NOTIFICATION_TITLE.STATUS[item] }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
	NOTIFICATION_TABLE_FIELDS,
	NOTIFICATION_COLOR,
	NOTIFICATION_TITLE,
} from '../enums/mobileNotification';
import {
	cleanObject,
	cloneDeep,
} from '../assets/js/helpers';

export default {
	name: 'MobileNotification',
	components: { },
	data() {
		return {
			NOTIFICATION_TABLE_FIELDS,
			NOTIFICATION_COLOR,
			NOTIFICATION_TITLE,
			queryParams: {
				page: Number(this.$route.query.page) || 1,
			},
		};
	},
	computed: {
		...mapState('mobileNotification', {
			list: 'list',
		}),
		isLoading() {
			return this.list.isLoading;
		},
	},
	async created() {
		await this.getNotifications(this.queryParams);
	},
	methods: {
		...mapActions({
			getNotifications: 'mobileNotification/getNotifications',
		}),

		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},

		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
	},
};
</script>

<style lang="scss" scoped>
	.badge-status {
		min-width: rem(65);
	}
</style>
